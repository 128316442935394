<script>
import project from "./project.vue";
import sample from "./sample.vue";
import passRate from "./passRate.vue";
import lineBox from "./lineBox.vue"
import {carouselJs} from "@/views/system/carousel";
import {getRead, setRead} from "@/utils/cookies";
export default {
  name: "index",
  components:{
    project,
    sample,
    passRate,
    lineBox,
  },
  data(){
    return{
      apiUrl:{
        list: '/api/index1/total',
        newList: '/api/bxs/total',
        dict: '/api/bxs/dict/',
      },
      queryParams:{},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 182);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今年',
          onClick(picker) {
            const end = new Date();
            const start = new Date(end.getFullYear(), 0, 1);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      decideOutcome: [
        {value: '1', label: "合格"},
        {value: '0', label: "不合格"},
        {value: '2', label: "无效"}
      ],
      yearValue:'',
      projectData: [],
      sampleData: [],
      passRateData:{},
      lineData:[],
      cardType:[],
      activeIndex: 0,

      open: false,
      carouselList: carouselJs(),
      initialIndex: 0,
    }
  },
  created() {
    this.getDict();
    this.getList();
    if(parseInt(getRead()) === 1){
      this.open = true
    }
  },
  methods:{
    getDict(){
      this.getApiConfig(this.apiUrl.dict,'reagent_card_specs').then((res)=>{
        this.cardType = res.data
        this.cardType.unshift({
          dictLabel: '全部',
          dictValue: ''
        })
      })
    },
    changeYear(yearValue){
      if(yearValue){
        this.queryParams = {
          startTime: yearValue[0],
          endTime: yearValue[1],
        }
      }else{
        this.queryParams = {}
      }
      this.getList()
    },
    getList(){
      this.$modal.loading("正在请求数据，请稍后...");
      this.postApiConfig(this.apiUrl.newList,this.queryParams).then((res)=>{
        this.$modal.closeLoading();
        console.log(res)
        this.projectData = res.data.projectCounts
        this.sampleData = res.data.sampleCount
        this.passRateData = res.data.detectedData
        let data =  res.data.monthCheckCount.sort((a, b) => a.checkDate.localeCompare(b.checkDate));
        this.lineData = data
      }).catch(()=>{
        this.$modal.closeLoading();
      })
    },
    goHistory(index){
      if(!index){
        const query = {};
        if(this.queryParams.authType){
          query.authType = this.queryParams.authType
        }
        this.$router.push({ path: '/history', query });
        return false
      }
      const outcomeMap = {
        1: '合格',
        2: '不合格',
        3: '无效',
      };

      // 默认决定结果为 ''
      const decideOutcome = outcomeMap[index] || '';
      // console.log("decideOutcome",decideOutcome)
      const filter = this.decideOutcome.find((item) => item.label === decideOutcome)
      // console.log("filter",filter)
      // 构建查询参数对象
      const query = { decideOutcome: filter.value };
      if(this.queryParams.authType){
        query.authType = this.queryParams.authType
      }
      if(this.queryParams.startTime && this.queryParams.endTime){
        query.startTime = this.queryParams.startTime
        query.endTime = this.queryParams.endTime
      }

      // 跳转路径保持不变
      this.$router.push({ path: '/history', query });
    },
    clickTab(index){
      this.activeIndex = index;
      if(index !== 0){
        this.queryParams.authType = this.cardType[index].dictValue
      }else{
        delete this.queryParams.authType
      }
      this.getList()
    },
    // 使用帮助
    defaultStep() {
      this.open = false;
      this.postApiConfig('/check/updateReadType').then(()=>{
        setRead(2);
      })
    },
    upStep() {
      this.initialIndex--;
      this.$refs.carousel.setActiveItem(this.initialIndex)
    },
    nextStep() {
      this.initialIndex++;
      this.$refs.carousel.setActiveItem(this.initialIndex)
    },
    clickLi(index) {
      this.initialIndex = index
      this.$refs.carousel.setActiveItem(index)
    },
    handleClose(){
      this.open = false;
      this.postApiConfig('/check/updateReadType').then(()=>{
        setRead(2);
      })
    },
  }
}
</script>

<template>
  <div class="home-page">
    <div class="home-page-left">
      <div class="home-page-left-top">
        <div class="time-tab-box">
          <div class="tab">
            <div class="tab-item" v-for="(item,index) in cardType" :key="index" :class="{'active':activeIndex === index }" @click="clickTab(index)">
              <span>{{ item.dictLabel }}</span>
            </div>
          </div>
          <div class="time">
            <el-date-picker
                v-model="yearValue"
                size="small"
                type="daterange"
                unlink-panels
                start-placeholder="开始日期"
                range-separator="至"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                align="right"
                style="width: 100%;"
                @change="changeYear(yearValue)">
            </el-date-picker>
          </div>
        </div>
<!--        <div class="title">-->
<!--          <span>检测数据</span>-->
<!--        </div>-->
        <div class="data-info">
          <div class="data-info-item" @click="goHistory()">
            <div class="item-num">
              {{ passRateData.detectedNumber || 0 }}
            </div>
            <div class="item-title">
              <span>检测批次</span>
            </div>
          </div>
          <div class="data-info-item" @click="goHistory(1)">
            <div class="item-num">
              {{ passRateData.passesNumber || 0 }}
            </div>
            <div class="item-title">
              <span>合格次数</span>
            </div>
          </div>
          <div class="data-info-item" @click="goHistory(2)">
            <div class="item-num">
              {{ passRateData.unqualifiedNumber || 0 }}
            </div>
            <div class="item-title">
              <span>不合格次数</span>
            </div>
          </div>
          <div class="data-info-item" @click="goHistory(3)">
            <div class="item-num">
              {{ passRateData.lapseNumber || 0 }}
            </div>
            <div class="item-title">
              <span>无效</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home-page-left-bottom">
        <div class="home-page-left-bottom-title">
          <span>检测排行</span>
        </div>
        <div class="home-page-left-bottom-echarts">
          <div class="home-page-left-bottom-echarts-left">
            <project :setProjectData="projectData"/>
          </div>
          <div class="home-page-left-bottom-echarts-right">
            <sample :setSampleData="sampleData"/>
          </div>
        </div>
      </div>
    </div>
    <div class="home-page-right">
      <div class="home-page-right-top">
        <passRate :setPassRateData="passRateData"/>
      </div>
      <div class="home-page-right-bottom">
        <lineBox :setLineData="lineData"/>
      </div>
    </div>
    <!-- 使用帮助 -->
    <div>
      <el-dialog title="欢迎使用胶体金检测" :visible.sync="open" width="900px" :before-close="handleClose">
        <div class="guidanceMain">
          <el-carousel ref="carousel" height="480px" :autoplay="false" indicator-position="none" arrow="never" :initial-index="initialIndex">
            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
              <div class="itemBox">
                <div class="imgBox">
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="textBox">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="text">
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div class="tag">
            <div v-for="(item,index) in carouselList.length" :key="index" class="tagLi"
                 :class="initialIndex == index ? 'new':'default'" @click="clickLi(index)"></div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" plain @click="upStep" v-if="initialIndex > 0">上 一 步</el-button>
          <el-button @click="nextStep" v-if="carouselList.length -1  != initialIndex">下 一 步</el-button>
          <el-button type="primary" @click="defaultStep" v-else>我 知 道 了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
.home-page{
  width: 100%;
  height: 90vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 20px;

  .home-page-left{
    flex: 2;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px 0;

    .home-page-left-top{
      width: 100%;
      flex: 1;
      background-color: #ffffff;
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      .time-tab-box{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tab{
          display: flex;
          justify-content: space-between;
          align-items: center;

          .tab-item{
            width: 3.75rem  /* 60/16 */;
            font-size: 1.25rem  /* 20/16 */;
            font-weight: bolder;
            line-height: 2.5rem  /* 40/16 */;
            text-align: center;
            cursor:pointer;
          }
        }
        .active{
          color: #1890FF;
          position: relative;

          &::after{
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -0.625rem  /* -10/16 */;
            width: 1.25rem  /* 20/16 */;
            height: 0.25rem  /* 4/16 */;
            border-radius: 0.125rem  /* 2/16 */;
            background-color: #1890FF;
          }
        }
      }
      .title{
        width: 100%;
        height: 40px;
        font-size: 1.625rem  /* 26/16 */;
        font-weight: bolder;
        text-align: center;
        line-height: 40px;
      }
      .data-info{
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .data-info-item{
          width: 23.5%;
          height: 80%;
          box-sizing: border-box;
          border-radius: 1.25rem  /* 20/16 */;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.625rem  /* 10/16 */ 0;
          cursor: pointer;
          transition: all 0.6s;  /*所有的属性在0.6秒之内完成改变*/

          .item-num{
            width: 100%;
            text-align: center;
            font-size: 2.5rem  /* 40/16 */;
            font-weight: bold;
            color: #333333;
          }
          .item-title{
            width: 100%;
            text-align: center;
            font-size: 1.25rem  /* 20/16 */;
            font-weight: 400;
            color: #000000;
          }

          &:nth-child(1){
            background-color: #e7fdff;
          }
          &:nth-child(2){
            background-color: #eefff2;
          }
          &:nth-child(3){
            background-color: #ffe6e6;
          }
          &:nth-child(4){
            background-color: #efefef;
          }

          &:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: scale(1.05);/*这个是根据图片比例放大到图片的1.6倍*/
          }
        }
      }
    }
    .home-page-left-bottom{
      width: 100%;
      flex: 1.5;
      background-color: #ffffff;
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .home-page-left-bottom-title{
        width: 100%;
        height: 40px;
        font-size: 1.625rem  /* 26/16 */;
        font-weight: bolder;
        text-align: center;
        line-height: 40px;
      }
      .home-page-left-bottom-echarts{
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 20px;
        .home-page-left-bottom-echarts-left{
          flex: 1;
          height: 100%;
        }
        .home-page-left-bottom-echarts-right{
          flex: 1;
          height: 100%;
        }
      }
    }
  }
  .home-page-right{
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px 0;

    .home-page-right-top{
      width: 100%;
      flex: 1;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 20px;
      background-color: #ffffff;
    }
    .home-page-right-bottom{
      width: 100%;
      flex: 1;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 20px;
      background-color: #ffffff;
    }
  }
}
.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .tagLi {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 6px;
  }
  .default {
    background-color: #CCE0F3;
  }
  .new {
    background-color: #0062c4;
  }
}
</style>
