<script>
export default {
  name: "index",
  props:{
    setProjectData:{
        type:Array,
        default:()=>[],
    },
  },
  watch: {
    setProjectData: {
      handler(newValue) {
        if (newValue.length > 0) {
          // 直接更新到options，避免使用额外的data对象
          this.options.xAxis.data = newValue.map(item => item.entryName);
          this.options.series[0].data = newValue.map(item => item.checkCount);

          // 使用setOption更新图表数据
          if (this.chartInstance) {
            this.chartInstance.setOption({
              ...this.options, // 保持其他配置不变
              xAxis: { data: this.options.xAxis.data },
              series: [{ ...this.options.series[0], data: this.options.series[0].data }],
            });
          }
        } else {
          // 数据为空时，清空数据
          this.options.xAxis.data = [];
          this.options.series[0].data = [];
          if (this.chartInstance) {
            this.chartInstance.setOption(this.options);
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      chartInstance:null,
      options: {
        grid: {
          left: '5%', // y轴距离左边界的百分比
          right: '3%', // 图表距离右边界的百分比
          top: '15%', // 图表距离上边界的百分比
          bottom: '8%', // 图表距离下边界的百分比
          containLabel: true, // 根据标签自动调整网格大小
        },
        title: {
          text: '项目排行',
          show: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: [], // 根据实际x轴类目替换
          axisLabel: {
            interval: 0, // 显示所有标签
            rotate: 15, // 设置为负数，如-45，可以使标签逆时针旋转45度
          },
          scrollbar: {
            show: true,
            scrollBtnEnabled: true, // 是否显示滚动按钮
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#007eff',
              }
            },
            data: [],
          }
        ],
        dataZoom: [
          {
            type: "slider", //隐藏或显示（true）组件
            show: true,
            backgroundColor: "rgb(204, 204, 204)", // 组件的背景颜色。
            fillerColor: "rgb(0, 126, 255)", // 选中范围的填充颜色。
            borderColor: "rgb(204, 204, 204)", // 边框颜色
            showDetail: false, //是否显示detail，即拖拽时候显示详细数值信息
            startValue: 0,
            endValue: 5,
            filterMode: "empty",
            width: "100%", //滚动条高度
            height: 10, //滚动条显示位置
            left: "center",
            zoomLoxk: true, // 是否锁定选择区域（或叫做数据窗口）的大小
            handleSize: 0, //控制手柄的尺寸
            bottom: 3, // dataZoom-slider组件离容器下侧的距离
          },
          {
            //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
            type: "inside",
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ]
      }
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        const el = this.$refs.echartsBar;
        this.chartInstance = this.$echarts.init(el);
        this.chartInstance.setOption(this.options);
      })
    },
    onResize() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<template>
  <div ref='echartsBar' style='width: 100%;height: 100%;'></div>
</template>

<style scoped lang="scss">

</style>
