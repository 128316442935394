<script>
export default {
  name: "lineBox",
  props:{
    setLineData:{
      type:Array,
      default:()=>[],
    },
  },
  watch: {
    setLineData: {
      handler(newValue) {
        if (newValue.length > 0) {
          // 直接更新到options，避免使用额外的data对象
          this.options.xAxis.data = newValue.map(item => item.checkDate);
          this.options.series[0].data = newValue.map(item => item.count);

          // 使用setOption更新图表数据
          if (this.chartInstance) {
            this.chartInstance.setOption({
              ...this.options, // 保持其他配置不变
              xAxis: { data: this.options.xAxis.data },
              series: [{ ...this.options.series[0], data: this.options.series[0].data }],
            });
          }
        } else {
          // 数据为空时，清空数据
          this.options.xAxis.data = [];
          this.options.series[0].data = [];
          if (this.chartInstance) {
            this.chartInstance.setOption(this.options);
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      chartInstance:null,
      options: {
        grid: {
          left: '0', // y轴距离左边界的百分比
          right: '3%', // 图表距离右边界的百分比
          top: '15%', // 图表距离上边界的百分比
          bottom: '0', // 图表距离下边界的百分比
          containLabel: true, // 根据标签自动调整网格大小
        },
        title: {
          text: '近一周检测次数',
          show: true,
          left: 'center',
        },
        tooltip: {
          trigger: "axis", // 触发类型为轴
          formatter: function (params) {
            var name = "检测日期：" + params[0].name;
            var value = "检测次数：" + params[0].value;
            return name + "<br/>" + value;
          },
        },
        xAxis: {
          type: 'category',
          data: [], // 根据实际x轴类目替换
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            type: 'line',
            smooth: true,
            data: [],
            symbol: "circle", //设定为实心点
            itemStyle: {
              color: "#1793FF", // 圆点内部颜色为蓝色
              borderColor: "#00EDDF", // 节点边框颜色为蓝色
              borderWidth: 2, // 节点边框宽度为 2px
            },
            symbolSize: 10, // 节点大小为 8px
          }
        ]
      }
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        const el = this.$refs.echartsBar;
        this.chartInstance = this.$echarts.init(el);
        this.chartInstance.setOption(this.options);
      })
    },
    onResize() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<template>
  <div ref='echartsBar' style='width: 100%;height: 100%;'></div>
</template>

<style scoped lang="scss">

</style>
