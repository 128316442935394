import imgUrl_1 from '@/assets/img/carousel/1.png'
import imgUrl_2 from '@/assets/img/carousel/2.png'
import imgUrl_3 from '@/assets/img/carousel/3.png'
import imgUrl_4 from '@/assets/img/carousel/4.png'

export function carouselJs() {
    let carouselList = [
        {
            imgUrl:imgUrl_1,
            name:'第一步',
            text:'选择菜单栏中的系统设置，右侧填写检测设置，信息无误后点击确认。',
        },
        {
            imgUrl:imgUrl_2,
            name:'第二步',
            text:'选择菜单栏中的农药残留，进入页面后，在页面左侧点击要检测的项目名称。',
        },
        {
            imgUrl:imgUrl_3,
            name:'第三步',
            text:'农药残留页面左侧，点击了检测项目后，右侧信息栏会显示项目相对应数据，然后点击选择样品名称。',
        },
        {
            imgUrl:imgUrl_4,
            name:'第四步',
            text:'农药残留页面右侧数据完全展示后，点击右下角的“读取”按钮，即可开始检测。',
        },
    ];
    return carouselList;
}
