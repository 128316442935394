<script>
export default {
  name: "passRate",
  props:{
    setPassRateData:{
      type: Object,
      default:()=>{},
    },
  },
  watch: {
    // 监听数据变化，实时更新饼图
    setPassRateData: {
      handler(newValue) {
        console.log("数据更新", newValue);
        if (Object.keys(newValue).length > 0) {
          // 更新饼图数据
          this.options.series[0].data = [
            { value: newValue.passesNumber, name: "合格", itemStyle: { color: "#43c8ff" } },
            { value: newValue.unqualifiedNumber, name: "不合格", itemStyle: { color: "#ff7676" } },
            { value: newValue.lapseNumber, name: "无效", itemStyle: { color: "#cecece" } },
          ];

          // 如果图表实例存在，则更新图表数据
          if (this.chartInstance) {
            this.chartInstance.setOption({
              series: this.options.series,
            });
          }
        } else {
          // 数据为空时，清空图表数据
          this.options.series[0].data = [];
          if (this.chartInstance) {
            this.chartInstance.setOption(this.options);
          }
        }
      },
      immediate: true, // 初始化时立即执行
    },
  },
  data() {
    return {
      chartInstance:null,
      options: {
        // 网格配置，控制图表与边界的距离
        grid: {
          left: '0',
          right: '3%',
          top: '15%',
          bottom: '0',
          containLabel: true,
        },
        // 图例配置，展示不同类别的标签
        legend: {
          data: ["合格", "不合格", "无效"],
          orient: "horizontal", // 修改为垂直布局
          left: 'center', // 居中对齐，若需靠右可改为 'right'
          bottom: "0",
          itemWidth: 16,
          itemGap: 20, // 根据实际情况调整间距
          textStyle: {
            color: "#333",
            fontSize: 14
          },
        },

        // 图表标题
        title: {
          text: '检测合格率',
          show: true,
          left: 'center',
        },

        // 提示框配置，当鼠标悬停时触发
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },

        // 系列数据配置，这里是饼图
        series: [
          {
            type: "pie",
            radius: ['0%', '60%'],
            avoidLabelOverlap: true,
            data: [
              { value: 0, name: "合格", itemStyle: { color: "#43c8ff" } },
              { value: 0, name: "不合格", itemStyle: { color: "#ff7676" } },
              { value: 0, name: "无效", itemStyle: { color: "#cecece" } },
            ],
            label: {
              formatter: "{b}: {d}%",
              alignTo: 'labelLine',
              textStyle: { color: '#000' },
            },
          },
        ],
      }
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        const el = this.$refs.echartsBar;
        this.chartInstance = this.$echarts.init(el);
        this.chartInstance.setOption(this.options);
      })
    },
    onResize() {
      console.log("触发了吗")
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<template>
  <div ref='echartsBar' style='width: 100%;height: 100%;'></div>
</template>

<style scoped lang="scss">

</style>
